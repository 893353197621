import $ from 'jquery';
global.jQuery = $;
global.$ = $;
import '../scss/styles.scss';
import Inputmask from "inputmask";
import debounce from 'lodash/debounce'
let url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
let token = "330be35e7df77755c758951be8cb337aaaf6e204";
let query = '';
(function ($) {

    let days = 24 * 60 * 60,
        hours = 60 * 60,
        minutes = 60;

    $.fn.countdown = function (prop) {

        let options = $.extend({
            callback: function () {},
            timestamp: 0
        }, prop);

        let left, d, h, m, s, positions;

        init(this, options);

        positions = this.find('.timer__value');

        (function tick() {

            // Осталось времени
            left = Math.floor((options.timestamp - (new Date())) / 1000);

            if (left < 0) {
                left = 0;
            }

            // Осталось дней
            d = Math.floor(left / days);
            updateDuo(0, 1, d);
            left -= d * days;

            // Осталось часов
            h = Math.floor(left / hours);
            updateDuo(2, 3, h);
            left -= h * hours;

            // Осталось минут
            m = Math.floor(left / minutes);
            updateDuo(4, 5, m);
            left -= m * minutes;

            // Осталось секунд
            s = left;
            updateDuo(6, 7, s);

            options.callback(d, h, m, s);

            setTimeout(tick, 1000);
        })();

        function updateDuo(minor, major, value) {
            switchDigit(positions.eq(minor), Math.floor(value / 10) % 10);
            switchDigit(positions.eq(major), value % 10);
        }

        return this;
    };


    function init(elem, options) {
        $.each(['Дней', 'Часов', 'Минут', 'Секунд'], function (i) {
            $(`<div class="timer__col">`).html(
                `<div class="timer__block">
                    <div class="timer__value">
                        <div class="timer__digit timer__digit_static">0</div>
                    </div>
                    <div class="timer__value">
                        <div class="timer__digit timer__digit_static">0</div>
                    </div>
                </div>
                <div class="timer__label">${this}</div>`
            ).appendTo(elem);
        });

    }

    function switchDigit(position, number) {

        let digit = position.find('.timer__digit')

        if (digit.is(':animated')) {
            return false;
        }

        if (position.data('digit') == number) {
            return false;
        }

        position.data('digit', number);

        var replacement = $('<div>', {
            'class': 'timer__digit',
            css: {
                top: '-1em',
                opacity: 0
            },
            html: number
        });

        digit
            .before(replacement)
            .removeClass('timer__digit_static')
            .animate({
                top: '1em',
                opacity: 0
            }, 200, function () {
                digit.remove();
            })

        replacement
            .delay(100)
            .animate({
                top: 0,
                opacity: 1
            }, 200, function () {
                replacement.addClass('timer__digit_static');
            });
    }
})(jQuery);
function getData () {
    let options = {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
        },
        body: JSON.stringify({
            query: $(this).val(),
            locations: [{"kladr_id": "61"}],
            count: 5
        })
    }
    $('.request-form__suggestions').html('')
    if ($(this).val()) {
        fetch(url, options)
        .then(response => response.json())
        .then((result) => {
            // console.log(result.suggestions);
            result.suggestions.forEach(element => {
                $('.request-form__suggestions').append(`<div class="request-form__suggestion-item">${element.value}<br> ИНН: ${element.data.inn}; ${element.data.address.value}</div>`)
            });
            $('.request-form__suggestions').show();
        })
        .catch((error) => {
            console.log("error", error)
        });
    }

}
$(function () {
	let ts = new Date(2020, 10, 1);
	$('.timer').countdown({
		timestamp: ts
    });
    $('.hamburger').on('click', function () {
        $(this).toggleClass('.is-active')
        $('.header-menu').slideToggle()
    })
    $('.header-menu__item, .competition__button').on('click', function (e) {
        e.preventDefault()
        let id = $(this).attr('href')
        let top = $(id).offset().top
        $('body, html').animate({
            scrollTop: top - 20
        }, 500)
    })

    $("input[name='orgName']").on('input', debounce(getData,  500))
    let im = new Inputmask("+7 (999) 999-99-99")
    let inputmask = document.getElementsByName('phone');
    let decimal = document.getElementsByName('graduateCount');
    Inputmask('decimal').mask(decimal)
    im.mask(inputmask);
    
    $('.request-form__suggestions').on('click', '.request-form__suggestion-item', function () {
        let $input = $(this).parents('.request-form__input-wr').find('.request-form__input')
        $input[0].value = $(this).text()
        $('.request-form__suggestions').hide()
    })
    $(document).mouseup(function (e){
		let $elem = $(".request-form__suggestions");
		if (!$elem.is(e.target)
		    && $elem.has(e.target).length === 0) {
			$elem.hide();
		}
    });
    $(document).on('submit', '.ajax_form', function() {
        $('.preloader').fadeIn(300)
    });
    $(document).on('af_complete', function(event, response) {
        if(response.success) {
            $('.request-form__input-wr .request-form__input').parents('.request-form__shadow-wr').removeClass('request-form__shadow-wr_focus')
        }
        $('.preloader').fadeOut(300)
    });
    if($('.request-form__input-wr .request-form__input').val()) {
        $('.request-form__input-wr .request-form__input').parents('.request-form__shadow-wr').addClass('request-form__shadow-wr_focus')
    }
    $('.request-form__input-wr .request-form__input').on('focus', function () {
        $(this).parents('.request-form__shadow-wr').addClass('request-form__shadow-wr_focus')
    })
    $('.request-form__input-wr .request-form__input').on('blur', function () {
        if(!$(this).val()) {
            $(this).parents('.request-form__shadow-wr').removeClass('request-form__shadow-wr_focus')
        }
    })
    $('.request-form__link_agreement').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.request-form__checkbox-wr').find('.request-form__hideText').slideToggle();
    })
})
